




















import { Component, Prop, Vue } from 'vue-property-decorator'
import Alert from '@/components/Alert/Alert.vue'

@Component({
  components: {
    Alert
  }
})
export default class FForm extends Vue {
  wasValidated = false
  hasFormError = false

  @Prop({ default: false, type: Boolean })
  hideValidations!: boolean;

  resetValidation(){
    this.wasValidated = false;
    this.hasFormError = false;
  }

  async onSubmit(e: Event) {
    this.wasValidated = true
    this.hasFormError = !(this.$refs.form as HTMLFormElement).checkValidity()
    if (this.hasFormError){
      (this.$refs.alert as Alert)?.restartAnimation();
      this.$nextTick(() => {
        (this.$refs.alertwrapper as Element)?.scrollIntoView({ behavior: 'smooth'})
      })
    }else{
      this.$emit('submit', e)
      await this.$nextTick;
      this.resetValidation();
    }
  }
}
